<template>
	<div>
		<a-layout>
			<a-layout-sider style="background: #FFFFFF;overflow: hidden; height: 100vh;" width="200px">
				<div style="padding: 30px 20px;">
					
				
					<div>
						<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<i class="iconfont ft14" :class="item.icon"></i>
							<span class="ft14 ml10">{{item.name}}</span>
						</div>

					</div>
				</div>
			</a-layout-sider>
			<a-layout-content style="padding: 30px 20px;height: 100vh; background: #F7F8FA;">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
		
		
	</div>
</template>

<script>
	
	export default {
		
		data() {
			return {
				showForm:false,
				
				menus: [{
						name: '小程序授权',
						icon: 'iconminiprogram02',
						key: 'miniapp_mine',
						link: '/miniapp'
					},
					{
						name: '主题设置',
						icon: 'icontheme_setting',
						key: 'miniapp_template',
						link: '/miniapp/template'
					},
					{
						name: '支付管理',
						icon: 'iconpayway',
						key: 'miniapp_paymanage',
						link: '/miniapp/paymanage'
					},
					{
						name: '推荐小程序',
						icon: 'iconapplication_tab02',
						key: 'miniapp_recommend',
						link: '/miniapp/recommend'
					},
					
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
			
		}
	}
</script>

<style>
	
</style>
